import React, { useState, useEffect, useRef } from 'react'

import useIsMounted from 'src/js/utils/hooks/useIsMounted'

import { TRUSTPILOT_APP_ID, TRUSTPILOT_URL } from 'src/constants'

// Styles & Images:
import 'src/components/layout/Footer/scss/ModuleTrustpilot.scss'

// Helpers:
const templates = {
  Carousel: '53aa8912dec7e10d38f59f36',
  MicroCombo: '5419b6ffb0d04a076446a9af',
}

const ModuleTrustpilot = ({
  stars = 5,
  template = templates.MicroCombo,
  theme = 'dark',
  dataTags = 'SelectedReview',
  height = '20px',
  width = '100%',
}) => {
  const [loaded, setLoaded] = useState(false)
  const ref = useRef(null)

  const isMounted = useIsMounted()

  useEffect(() => {
    let interval // eslint-disable-line

    const loadLibrary = () => {
      if (isMounted && typeof window !== 'undefined' && window.Trustpilot) {
        ref.current && window.Trustpilot.loadFromElement(ref.current)

        setLoaded(true)

        interval && clearInterval(interval)
      }
    }
    loadLibrary()

    interval = setInterval(loadLibrary, 1)

    /* in case component unmounts before window.Trustpilot becomes available */
    return () => clearInterval(interval)
  }, [isMounted])

  return (
    loaded && (
      <div className="x__trustpilot">
        <div
          ref={ref}
          className="x__trustpilot__widget trustpilot-widget"
          id="trustpilot-widget"
          data-locale="en-US"
          data-font-family="Roboto"
          data-template-id={template}
          data-businessunit-id={TRUSTPILOT_APP_ID}
          data-style-height={height}
          data-style-width={width}
          data-theme={theme}
          data-tags={dataTags}
          data-stars={stars}
        >
          <a href={TRUSTPILOT_URL} target="_blank" rel="noreferrer">
            Trustpilot
          </a>
        </div>
      </div>
    )
  )
}

export default ModuleTrustpilot
