import React from 'react'

import Button from 'components/button'

import 'src/components/newsletter/Subscribe/scss/Module.scss'

const moduleData = {
  title: 'Subscribe to Exodus',
  message: 'Sign up to receive our newsletter with updates about your wallet.',
  ctaText: 'Sign me up',
  ctaAction: '/newsletter/',
}

function SubscribeModule({ data = moduleData }) {
  return (
    <div className="x__subscribe-module">
      <p className="x__subscribe-module__heading">{data.title}</p>
      <p className="x__subscribe-module__copy">{data.message}</p>

      <Button to={data.ctaAction} copy={data.ctaText} size="largest" />
    </div>
  )
}

export default SubscribeModule
