import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import Button from 'components/button'
import { getCookie, setCookie } from 'js/utils/cookies'

// Styles & Images:
import 'components/newsletter/Subscribe/scss/Popup.scss'

// Helpers:
const isCookieNotSet = () => getCookie('ex_subscribe') !== '1'
const isSessionCookieNotSet = () => getCookie('ex_subscribe_session') === undefined

// Partials:
const CloseIcon = ({ onClick }) => (
  <div className="x__close" onClick={onClick}>
    <svg width="14" height="14" xmlns="http://www.w3.org/2000/svg" role="img" aria-label="Close">
      <title>Close</title>
      <desc>Click to close popup</desc>
      <g fill="#FFF" fillRule="nonzero">
        <rect
          transform="rotate(45 6.839 6.839)"
          x="-2.161"
          y="6.339"
          width="18"
          height="1"
          rx=".5"
        />
        <rect
          transform="scale(-1 1) rotate(45 0 -9.672)"
          x="-2.161"
          y="6.339"
          width="18"
          height="1"
          rx=".5"
        />
      </g>
    </svg>
  </div>
)

const Content = ({ isInvestorNewsletter }) => {
  const data = {
    title: isInvestorNewsletter
      ? 'Subscribe to the Investor Newsletter'
      : 'Subscribe to the Exodus Newsletter',
    message: isInvestorNewsletter
      ? 'Stay informed about Company financials and investor communications'
      : 'Don’t miss out on the latest Exodus news and product updates!',
    ctaText: 'Sign me up',
    ctaAction: isInvestorNewsletter ? '/investor/newsletter/' : '/newsletter/',
  }

  return (
    <div className="x__content">
      <span className="x__content__title">{data.title}</span>
      <span className="x__content__copy" dangerouslySetInnerHTML={{ __html: data.message }} />
      <Button copy={data.ctaText} to={data.ctaAction} />
    </div>
  )
}

/* Related cookies
 * ex_subscribe
 *  - set to "1" permanently if the user subscribes
 *  - set to "1" for 60 days if the user closes the notice
 *  - when set to "1" the notice will not be shown
 *
 * ex_subscribe_session
 *  - set to "1" each time the notice is shown
 *  - when set to "1" the notice will not be shown
 *  - not permanent
 *  - the effect is to show the banner once per session, on the first page the user visits
 */

// Main Component:
const SubscribePopup = ({ variant = 'product' }) => {
  const [show, setShow] = useState(isCookieNotSet() && isSessionCookieNotSet())
  const [closed, setClosed] = useState(false)
  const [doneAnimating, setDoneAnimating] = useState(false)

  const isInvestorNewsletter = variant === 'investor'

  const close = () => {
    setClosed(true)

    // if closing without having subscribed.
    if (isCookieNotSet()) {
      setCookie({
        name: isInvestorNewsletter ? 'ex_subscribe_investor' : 'ex_subscribe',
        value: '1',
        daysToPersist: 60,
      })
    }
  }

  useEffect(() => {
    /*
     * Do not show if cookie banner is present.
     * A timeout of 3500ms gives the cookie banner time to appear
     * while also being less than the 4s mark at which the notice
     * animates in (delay set on css animation prop).
     */

    const timeout1 = setTimeout(() => {
      if (
        typeof document !== 'undefined' &&
        document.getElementsByClassName('x-cookies-banner').length === 0
      ) {
        setCookie({
          name: isInvestorNewsletter ? 'ex_subscribe_investor_session' : 'ex_subscribe_session',
          value: '1',
        })
      } else {
        setShow(false)
      }
    }, 3500)

    const timeout2 = setTimeout(() => {
      setDoneAnimating(true)
    }, 4000)

    return () => {
      clearTimeout(timeout1)
      clearTimeout(timeout2)
    }
  }, [])

  if (!show) return null

  return (
    <div
      className={classNames('x__subscribe-popup', {
        'x__subscribe-popup--done-animating': doneAnimating,
        'x__subscribe-popup--closed': closed,
      })}
    >
      <div className="x__subscribe-popup__image" />
      <div className="x__subscribe-popup__content">
        <CloseIcon onClick={close} />
        <Content isInvestorNewsletter={isInvestorNewsletter} />
      </div>
    </div>
  )
}

export default SubscribePopup
